// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["h7Rvf7_vO"];

const variantClassNames = {h7Rvf7_vO: "framer-v-yx4zbp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "h7Rvf7_vO", image: uderGGV9Q = {src: new URL("assets/0ng8vND3WG9aQUECBqxYSyskEY.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/0ng8vND3WG9aQUECBqxYSyskEY.jpg", import.meta.url).href} 512w, ${new URL("assets/0ng8vND3WG9aQUECBqxYSyskEY.jpg", import.meta.url).href} 620w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "h7Rvf7_vO", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-0GnTd", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-yx4zbp", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"h7Rvf7_vO"} ref={ref} style={{...style}} transition={transition}><Image background={{alt: "", fit: "fit", intrinsicHeight: 490, intrinsicWidth: 620, pixelHeight: 490, pixelWidth: 620, ...toResponsiveImage_194x2gw(uderGGV9Q)}} className={"framer-1wk074f"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"imTlLTMxw"} style={{filter: "drop-shadow(0px 0px 13px rgba(0, 0, 0, 0.25))", WebkitFilter: "drop-shadow(0px 0px 13px rgba(0, 0, 0, 0.25))"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-0GnTd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0GnTd * { box-sizing: border-box; }", ".framer-0GnTd .framer-4h6nbf { display: block; }", ".framer-0GnTd .framer-yx4zbp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 30px 30px 30px; position: relative; width: 1080px; }", ".framer-0GnTd .framer-1wk074f { aspect-ratio: 1.2646370023419204 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 807px); overflow: hidden; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-0GnTd .framer-yx4zbp { gap: 0px; } .framer-0GnTd .framer-yx4zbp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-0GnTd .framer-yx4zbp > :first-child { margin-left: 0px; } .framer-0GnTd .framer-yx4zbp > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 867
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"uderGGV9Q":"image"}
 */
const Framerz_f7pcGVL: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default Framerz_f7pcGVL;

Framerz_f7pcGVL.displayName = "User flow comp";

Framerz_f7pcGVL.defaultProps = {height: 867, width: 1080};

addPropertyControls(Framerz_f7pcGVL, {uderGGV9Q: {__defaultAssetReference: "data:framer/asset-reference,0ng8vND3WG9aQUECBqxYSyskEY.jpg?originalFilename=image.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerz_f7pcGVL, [])